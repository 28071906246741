<template>
  <div class="starfield">
    <div class="static"></div>
    <div class="moving-1"></div>
    <div class="moving-2"></div>
    <div class="moving-3"></div>
    <div class="demo-image">
      <img :src="url" class="img_logo" alt="荣耀存储">
    </div>
    <div class="download">
      <el-button type="primary" @click="download(android)">Android 下载</el-button>
      <el-button type="primary" @click="download(ios)">IOS 下载</el-button>
    </div>
    <div style="bottom:80px; padding-top: 2.5rem; margin:0 auto;">
      <el-button type="text" @click="register()">注 册</el-button>
    </div>
  </div>
</template>
<style>
.download {
  margin-top: 1.25rem;
}
.demo-image {
  margin: 0 auto;
  margin-top: 18.75rem;
  text-align: center;
  width: 6.25rem;
}
.img_logo{
  border-radius: .3125rem;
  width: 6.25rem;
}
@import url("../../css/style.css");
</style>

<script>
import router from "../../router/index";
export default {
  data() {
    return {
      android: 'https://sfkil.oss-cn-shenzhen.aliyuncs.com/apps/skfil.apk',
      // ios: 'itms-services://?action=download-manifest&url=https://skfil.com/assets/ios.plist',
      ios: 'https://testflight.apple.com/join/FXo4F0hS',
      url: require("../../assets/images/logo.png"),
    };
  },
  methods: {
    download(uri) {
      document.location.href = uri;
    },
    register() {
      router.push('/signin')
    }
  }
};
</script>
