<template>
  <div class="starfield">
    <div class="static"></div>
    <div class="moving-1"></div>
    <div class="moving-2"></div>
    <div class="moving-3"></div>
    <div class="home">
      <download />
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import download from "@/views/user/download.vue";

export default {
  name: "Home",
  components: {
    download,
  }
};
</script>

<style>
@import url("../css/style.css");
</style>
