import { createRouter, createWebHashHistory } from 'vue-router'
import Home from '../views/Home.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/download',
    name: 'Download',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "download" */ '../views/user/download.vue')
  },
  {
    path: "/signin",
    name: "Signin",
    component: () => import(/* webpackChunkName: "registered" */ '../views/user/registered.vue')
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
